<template>
  <div class="roleTab clearfix">
    <div v-if="tableData.length>0&&tableData[0].approvalStatus==='3'" style="margin-left: 31px;margin-bottom: 10px;margin-top: 10px;color: #c71313;">您的送货需求取消申请被驳回</div>
    <div class="apply-table">
      <global-table ref="libraryData" :tableField="tableField" :tableData="tableData"></global-table>
      <el-select v-model="cancelReasonClassify" placeholder="请选择取消原因" filterable>
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
      </el-select>
      <el-input type="textarea" v-model="desc" placeholder="请填写说明"></el-input>
    </div>
    <div class="apply-footer" v-if="cancelReasonClassify!='-1'&&tableData.length>0&&tableData[0].approval===loginname&&tableData[0].approvalStatus!=='3'">
      <el-button  @click="cancel">
       驳回
      </el-button>
      <el-button  @click="action" type="primary">
       提交
      </el-button>
    </div>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { tableField, tableData } from './module/ApplyBody'
import { request } from '@/assets/js/http.js'
export default {
  components: Component.components,
  name: 'SupplierDemandNew',
  data () {
    return {
      tableField: tableField,
      tableData: tableData,
      cancelReasonClassify: '',
      loginname: localStorage.getItem('userName'),
      options: [
        {
          value: '1',
          label: '不可抗力'
        },
        {
          value: '2',
          label: '直发'
        },
        {
          value: '3',
          label: '质量问题'
        },
        {
          value: '4',
          label: 'PO延期'
        },
        {
          value: '5',
          label: '尾数无法凑包'
        },{
          value: '6',
          label: '最小送货批调整'
        },
        {
          value: '7',
          label: '重复触发'
        },
        {
          value: '8',
          label: '需求无po可选'
        },
        {
          value: '9',
          label: '客户通知取消或延期（需求变更）'
        },
        {
          value: '10',
          label: '调整基地'
        }/* ,
        {
          value: '6',
          label: '其他'
        } */
      ],
      desc: '',
      buttonData: [{
        label: '取消',
        action: 'handleClose',
        size: 'mini'
      }, { label: '确认', action: 'action', type: 'primary', size: 'mini' }]
    }
  },
  computed: {
    ifShowDemandType: function () {
      if (this.tableData[0]) {
        return this.tableData[0].demandType === 8
      } else {
        return false
      }
    }
  },
  created () {
    this.myBidData()
    request('/api/system/user/getUserInfoAndMenuMessage', 'GET', {}).then((response) => {
      localStorage.setItem('userName', response.data.username)
      localStorage.setItem('userId', response.data.id)
      this.loginname = localStorage.getItem('userName')
    })
  },
  mounted () {
  },
  methods: {
    myBidData () {
      const obj = {
        id: this.$route.query.id,
        baseCode: '',
        demandOrderCode: '',
        demandTimeBeginTime: '',
        demandTimeEndTime: '',
        materialCode: '',
        materialDesc: '',
        pageNum: 1,
        pageSize: 10,
        releaseBeginTime: '',
        releaseEndTime: '',
        rkTime: ''
      }
      request('/api/livraison/livrasion/getLivrasionDemandDataByUserId', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.iPage.records
          this.cancelReasonClassify = this.tableData[0].cancelReasonClassify
          this.desc = this.tableData[0].cancelState
        }
      })
    },
    action () {
      const newChoiceData = []
      this.tableData.forEach(w => {
        newChoiceData.push({
          demandCode: w.demandCode,
          demandRow: w.demandRow
        })
      })
      const obj = {
        demandCodes: JSON.stringify(newChoiceData),
        applyExplain: this.desc,
        cancelReasonClassify:this.cancelReasonClassify
      }
      if (this.tableData[0].demandType === 8) {
        if (!this.cancelReasonClassify) {
          this.$message({
            showClose: true,
            message: '请选择取消原因',
            type: 'warning'
          })
        } else {
          obj.cancelReasonClassify = this.cancelReasonClassify
        }
      }
      let url = '/api/livraison/livrasion/cancelDemand'
      let msg = '取消成功！'
      if (localStorage.getItem('userName') !== 'zhangxy' && (this.cancelReasonClassify === '3' || this.cancelReasonClassify === '4')) {
        url = '/api/livraison/livrasion/updateDemand?type=1'
        msg = '提交成功，待领导审批！'
      }
      if (this.desc) {
        request(url, 'POST', obj).then((res) => {
          if (res.code === '200') {
            this.$message({
              showClose: true,
              message: msg,
              type: 'success'
            })
            this.$router.push({ name: 'ShowBox' })
          }
        })
      } else {
        this.$message({
          showClose: true,
          message: '请填写说明',
          type: 'warning'
        })
      }
    },
    cancel () {
      const newChoiceData = []
      this.tableData.forEach(w => {
        newChoiceData.push({
          demandCode: w.demandCode,
          demandRow: w.demandRow
        })
      })
      const obj = {
        demandCodes: JSON.stringify(newChoiceData),
        applyExplain: this.desc
      }
      const url = '/api/livraison/livrasion/updateDemand?type=3'
      const msg = '驳回成功！'
      if (this.desc) {
        request(url, 'POST', obj).then((res) => {
          if (res.code === '200') {
            this.$message({
              showClose: true,
              message: msg,
              type: 'success'
            })
            this.$router.push({ name: 'ShowBox' })
          }
        })
      } else {
        this.$message({
          showClose: true,
          message: '请填写说明',
          type: 'warning'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .tableNav{
    border: none;
  }
  .tableList{
    padding: 0 20px;
  }
  .roleTab .tableNav :deep(.searchList .col){
    min-width: 250px;
  }
  .roleTab .tableNav :deep(.searchList .col .title){
    min-width: 42px;
  }
  .apply-table .el-textarea{
    padding: 20px 20px 0;
    width: calc(100% - 40px);
  }
  .apply-table .el-select{
    padding: 20px 20px 0;
  }
  .apply-footer{
    padding: 20px;
    text-align: right;
  }
</style>
